var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"sc-page-sheet"},[_c('v-row',{staticClass:"my-2",attrs:{"align":"center","align-content":"center"}},[_c('i18n',{staticClass:"ml-4 primary--text text-uppercase text-h4",attrs:{"path":'menu.'+_vm.$route.name.toLowerCase()}}),_c('sc-dates-range',{staticClass:"ml-3",model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}}),_c('v-spacer'),_c('span',{attrs:{"width":"300px"}},[_c('v-text-field',{staticClass:"mr-3",attrs:{"append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-btn',{staticClass:"error ma-4",attrs:{"icon":"","dark":"","to":"/"}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-data-table',{staticClass:"transparent table-custom",attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"idx","footer-props":_vm.foot_props,"no-data-text":_vm.$t('label.nodata'),"items-per-page":_vm.itemPerPage,"options":_vm.pagination,"page":_vm.currentPage,"server-items-length":_vm.totalItems},on:{"update:itemsPerPage":function($event){_vm.itemPerPage=$event},"update:items-per-page":function($event){_vm.itemPerPage=$event},"update:options":function($event){_vm.pagination=$event},"update:page":function($event){_vm.currentPage=$event}},scopedSlots:_vm._u([{key:"item.client",fn:function(ref){
var item = ref.item;
return [_c('sc-client-avatar',{attrs:{"item":item}})]}},{key:"item.count",fn:function(ref){
var item = ref.item;
return [(item.count)?_c('span',[_vm._v(_vm._s(item.count))]):_vm._e()]}},{key:"item.comment",fn:function(ref){
var item = ref.item;
return [(item.visitdet)?_c('span',[_vm._v(_vm._s(item.visitdet.comment))]):_vm._e()]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [(item.wdet.idx)?_c('sc-time-interval',{attrs:{"start":item.wdet.beginat,"duration":item.wdet.duration,"color":item.wdet.color}}):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }